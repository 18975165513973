.page-loader-wrapper {
    @extend %gradient-top-bottom-right;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 999999999;
    top: 0;
    overflow: hidden;
}
.page-loader {
    animation: animate 2.5s linear infinite;
    background-color: transparent;
    // background-image: url('../images/unite-group-logo.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 50%;
    // border: 1px solid $main_white;
    bottom: 0;
    height: 400px;
    left: 0;
    margin: auto;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    width: 400px;
    z-index: 999;
    @include max-mq ($mb_l) {
        height: 340px;
        width: 340px;
    }
    @include max-mq ($mob + 10) {
        height: 290px;
        width: 290px;
    }
    @include max-mq ($xs) {
        height: 260px;
        width: 260px;
    }
    // &:before,
    // &:after {
    //     border-radius: 50%;
    //     bottom: 0;
    //     content: '';
    //     display: block;
    //     left: 0;
    //     margin: auto;
    //     position: absolute;
    //     right: 0;
    //     top: 0;
    // }
    // &:before {
    //     border: 2px solid $main_white;
    //     height: 70%;
    //     width: 70%;
    // }
    // &:after {
    //     border: 3px solid $main_white;
    //     height: 40%;
    //     width: 40%;
    // }
}

@keyframes animate {
    0% {
        transform: scale(1.2);
    }
    50% {
        transform: scale(.9);
    }
    100% {
        transform: scale(1.2);
    }
}