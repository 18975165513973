.site-header {
    position: relative;
    padding: 20px 15px;
    .site-header-logo-menu-navigation,
    .logo-menus-inner {
        align-items: center;
        display: flex;
    }
}
#header-phone {
    display: block;
    margin-right: 57px;
    @include max-mq ($md) {
        margin-right: 27px;
    }
    @include max-mq ($mb_l) {
        display: none;
    }
    a {
        font-size: $font-size-h3;
        font-weight: $bold;
        @include max-mq ($md) {
            font-size: $font-size-h3 - 5;
        }
    }
}

.burger-menu-phone-number-wrap {
    position: relative;
    top: -2px;
    @extend %flex-center;
    a {
        color: $main_white;
        &:hover {
            color: $grey_menu;
        }
    }
}
.main-navigation-header {
    padding-left: 78px;
    @include max-mq ($ls) {
        padding-left: 26px;
    }
    @include max-mq ($lg) {
        padding-left: 18px;
    }
    @include max-mq ($md) {
        display: none;
    }
}

.logo-menus-wrapper {
    padding: 10px 38px 0 49px;
    @include max-mq ($ls) {
        padding: 8px 20px 0 5px;
    }
    @include max-mq ($tab) {
        padding: 5px 20px 0 0;
    }
    @include max-mq ($mb_l) {
        padding: 0 14px 0 0;
    }
}
.site-branding-main-logo {
    max-width: 158px;
    img {
        width: 100%;
        display: block;
    }
}