/*General Style*/

* {

  &:focus,
  &:active,
  &:hover {
    outline: none;
  }

  &:before,
  &:after { /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
    box-sizing: inherit;
  }
}

* {

  &,
  &:hover,
  &:focus,
  &:active {
    -webkit-tap-highlight-color: transparent;
  }
}


body {
  color: $gray_paragraph;
  font-family: $primary_font;
  font-size: $font-size-base;
  line-height: 1.5;
  p {
      margin-bottom: 1.5em;
  }
  &.menu-active {
    overflow-y: hidden !important;
  }

}

.container {
  max-width: $container_width;
  width: 100%;
}

a {
    color: $gray_paragraph;
    text-decoration: none;
    transition: all .5s;
    &:hover {
        color: $orange_button;
    }
}

.site-content {
    a {
        
    }
}

/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/

@each $header, $size in (h1: 700 #{$font-size-h1}/#{$h1-line} $primary_font, h2: 500 #{$font-size-h2}/#{$h2-line} $primary_font, h3: 500 #{$font-size-h3}/#{$h3-line} $primary_font, h4: 400 #{$font-size-h4}/#{$h4-line} $primary_font, h5: 400 #{$font-size-h5}/#{$h5-line} $primary_font, h6: 300 #{$font-size-h6}/#{$h6-line} $primary_font) {
  body #{$header} {
    clear: both;
    color: $grey_dark_heading;
    font: $size;
    a {
        color: $grey_dark_heading;
    }
  }
}
body {
    h1 {
        @include max-mq ($ls) {
            font-size: $font-size-h1 - 11;
        }
        @include max-mq ($lg) {
            font-size: $font-size-h1 - 17;
        }
        @include max-mq ($mob) {
            font-size: $font-size-h1 - 18;
        }
    }
    h2 {
        @include max-mq ($ls) {
            font-size: $font-size-h2 - 6;
        } 
    }
}

p {
  margin-bottom: 1em;

  &:first-of-type {
    margin-top: 0;
  }
}

dfn,
cite,
em,
i {
  font-style: italic;
}

blockquote {
  margin: 0 1em;
}

address {
  margin: 0 0 1em;
}

pre {
  background: #eee;
  font-family: "Courier 10 Pitch", Courier, monospace;
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em;
}

code,
kbd,
tt,
var {
  font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
  font-size: 15px;
}

abbr,
acronym {
  border-bottom: 1px dotted #666;
  cursor: help;
}

mark,
ins {
  background: #fff9c0;
  text-decoration: none;
}

big {
  font-size: 125%;
}

ul,
ol {
  margin: 0;
}

ul {
  list-style: none;
  padding-left: 0;
}

ol {
  list-style: decimal;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 0.5em;
}

dt {
  font-weight: bold;
}

dd {
  margin: 0 1.5em 1.5em;
}

/*--------------------------------------------------------------
# Elements
--------------------------------------------------------------*/
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
}

blockquote,
q {
  quotes: "" "";
}

hr {
  background-color: #ccc;
  border: 0;
  height: 1px;
  margin-bottom: 1em;
}

img {
  height: auto; /* Make sure images are scaled correctly. */
  max-width: 100%; /* Adhere to container width. */
}

table {
  margin: 0 0 1em;
  width: 100%;
}

/*--------------------------------------------------------------
# Clearfix
--------------------------------------------------------------*/
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

* html .clearfix {
  height: 1%;
}

.clearfix {
  display: block;
}

.clear:before,
.clear:after,
.entry-content:before,
.entry-content:after,
.comment-content:before,
.comment-content:after,
.site-header:before,
.site-header:after,
.site-content:before,
.site-content:after,
.site-footer:before,
.site-footer:after {
  content: "";
  display: table;
  table-layout: fixed;
}

.clear:after,
.entry-content:after,
.comment-content:after,
.site-header:after,
.site-content:after,
.site-footer:after {
  clear: both;
}

/*--------------------------------------------------------------
# Widgets
--------------------------------------------------------------*/
.widget {
  margin: 0 0 1em;
}

/* Make sure select elements fit in widgets. */
.widget select {
  max-width: 100%;
}

/*--------------------------------------------------------------
## Posts and pages
--------------------------------------------------------------*/
.sticky {
  display: block;
}

.hentry {
  margin: 0 0 1em;
}

.byline,
.updated:not(.published) {
  display: none;
}

.single .byline,
.group-blog .byline {
  display: inline;
}

.page-content,
.entry-content,
.entry-summary {
  margin: 1em 0 0;
}

.page-links {
  clear: both;
  margin: 0 0 1em;
}

/*--------------------------------------------------------------
## Asides
--------------------------------------------------------------*/
.blog .format-aside .entry-title,
.archive .format-aside .entry-title {
  display: none;
}

/*--------------------------------------------------------------
## Comments
--------------------------------------------------------------*/
.comment-content a {
  word-wrap: break-word;
}

.bypostauthor {
  display: block;
}

/*--------------------------------------------------------------
## Comments
--------------------------------------------------------------*/
.comment-content a {
  word-wrap: break-word;
}

.bypostauthor {
  display: block;
}

/*--------------------------------------------------------------
# Infinite scroll
--------------------------------------------------------------*/
/* Globally hidden elements when Infinite Scroll is supported and in use. */
.infinite-scroll .posts-navigation, /* Older / Newer Posts Navigation (always hidden) */
.infinite-scroll.neverending .site-footer { /* Theme Footer (when set to scrolling) */
  display: none;
}

/* When Infinite Scroll has reached its end we need to re-display elements that were hidden (via .neverending) before. */
.infinity-end.neverending .site-footer {
  display: block;
}

/*--------------------------------------------------------------
# Media
--------------------------------------------------------------*/
.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
  border: none;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
}

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
  max-width: 100%;
}

/*--------------------------------------------------------------
## Captions
--------------------------------------------------------------*/
.wp-caption {
  margin-bottom: 1em;
  max-width: 100%;
}

.wp-caption img[class*="wp-image-"] {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption .wp-caption-text {
  margin: 0.8075em 0;
}

.wp-caption-text {
  text-align: center;
}

/*--------------------------------------------------------------
## Galleries
--------------------------------------------------------------*/
.gallery {
  margin-bottom: 0;

  img {
    display: block;
  }
}

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-caption {
  display: block;
}

.full-width {
  float: left;
  width: 100%;
}

.text-center,
.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.text-uppercase {
  text-transform: uppercase;
}

.alignleft {
  display: inline;
  float: left;
  margin-right: 1em;
}

.alignright {
  display: inline;
  float: right;
  margin-left: 1em;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.alignvertical{
  vertical-align: middle;
}
