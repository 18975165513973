.view-stock-cpt-posts-slider {
    .slick-arrow {
        color: $orange_button;
        top: auto;
        bottom: 0;
    }
    .view-stock-post {
        padding-bottom: 7px;
        &:nth-child(even) {
            .stock-images-main-wrapper {
                order: 2;
            }
            .view-stock-blog-title-excerpt-wrapper {
                order: 1;
            }
            .view-stock-post-title-text-description-wrap {
                padding-left: 0;
                padding-right: 25px;
                max-width: 423px;
                @include max-mq ($lg) {
                    margin-left: auto;
                    margin-right: 0;
                }
                @include max-mq ($md - 30) {
                    max-width: 100%;
                    padding-right: 0;
                }
                .view-stock-post-title-text-description-wrap-inner {
                    text-align: right;
                }
            }
            .view-stock-button-more {
                text-align: right;
            }
        }
        &:last-of-type {
            .view-stock-post-content-wrapper {
                .view-stock-posts-content-wrapper {
                    border-bottom: none;
                    padding-bottom: 7px;
                }
            }
        }
    }
}

.view-stock-cpt-content-inner {
    display: block;
    width: 100%;
}

.view-stock-post-content-wrapper {
    background-color: $main_white;
    border-radius: 17px;
    display: block;
    padding: 20px 35px;
    @include max-mq ($md - 30) {
        padding-bottom: 0;
    }
    @include max-mq ($mb_l) {
        padding: 15px 15px 0;
    }
    &:hover {
        color: inherit;
    }
    .view-stock-posts-content-wrapper {
        border-bottom: 1px solid $grey_twitter_border;
        padding-bottom: 57px;
        @include max-mq ($md - 30) {
            padding-bottom: 27px;
        }
    }
}
.view-stock-posts-content-inner {
    align-items: center;
    display: flex;
    @include max-mq ($md - 30) {
        display: block;
    }
    img {
        display: block;
    }
    .stock-images-main-wrapper {
        flex: 0 0 59.3%;
        max-width: 59.3%;
        @include max-mq ($md - 30) {
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
        }
        img {
            width: 100%;
        }
        .view-stock-posts-thumnail-wrapper {
            flex: 0 0 64.4%;
            max-width: 64.4%;
            @include max-mq ($mb_l) {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        .view-stock-repeater-gallery {
            display: flex;
            flex-wrap: wrap;
            flex: 0 0 35.6%;
            justify-content: space-between;
            max-width: 35.6%;
            padding-left: 25px;
            @include max-mq ($mb_l) {
                display: none;
            }
        }
        .stock-gallery-image {
            display: flex;
            width: 100%;
            padding-bottom: 8px;
            &:nth-child(2) {
                align-items: flex-end;
                padding-bottom: 0;
                padding-top: 8px;
            }
        }
    }
    .view-stock-blog-title-excerpt-wrapper {
        flex: 0 0 40.7%;
        font-size: $font-size-large;
        max-width: 40.7%;
        @include max-mq ($ipad) {
            font-size: $font-size-large - 2;
        }
        @include max-mq ($md - 30) {
            flex: 0 0 100%;
            max-width: 100%;
            padding-top: 29px;
            width: 100%;
        }
    }
    .view-stock-post-excerpt {
        border-bottom: 1px solid $grey_twitter_border;
        margin-bottom: 15px;
        p {
            line-height: 1.7;
            margin-bottom: 1.15em;
        }
    }
}
.stock-images-main-inner {
    align-items: center;
    display: flex;
}
.view-stock-post-title-text-description-wrap {
    max-width: 468px;
    padding-bottom: 25px;
    padding-left: 63px;
    width: 100%;
    @include max-mq ($lg) {
        padding-left: 25px;
    }
    @include max-mq ($md - 30) {
        padding-left: 0;
        max-width: 100%;
    }
    .view-stock-button-more {
        padding-top: 42px;
        .button {
            padding-left: 18px;
            padding-right: 18px;
        }
    }
}
.view-stock-post-title {
    color: $main_dark;
    font-size: $font-size-h2 - 6;
    font-weight: $bold;
    margin-top: 0;
    @include max-mq ($ipad) {
        font-size: $font-size-h2 - 9;
    }
    @include max-mq ($ip) {
        font-size: $font-size-h2 - 13;
    }
}
.product-details-inner {
    display: flex;
    flex-wrap: wrap;
    .product-details-item {
        padding-bottom: 3px;
    }
    .product-details-fuel {
        strong {
            padding-right: 5px;
        }
    }
    .product-details-lift_height {
        strong {
            padding-right: 5px;
        }
    }
    .product-details-plant_ref {
        strong {
            padding-right: 24px;
            @include max-mq ($lg) {
                padding-right: 5px;
            }
        }

    }
    .product-details-capacity,
    .product-details-fuel {
        flex: 0 0 53%;
        max-width: 53%;
        @include max-mq ($xs) {
            flex: 0 0 51.5%;
            max-width: 51.5%;
        }
    }
    .product-details-plant_ref,
    .product-details-lift_height {
        flex: 0 0 47%;
        max-width: 47%;
        @include max-mq ($xs) {
            flex: 0 0 48.5%;
            max-width: 48.5%;
        }
    }
}
.view-stock-cpt-posts-items {
    max-width: $container_width - 30;
    @extend %center-auto-positioning;
}
.product-details-section {
    padding-top: 9px;
    @include max-mq ($mob + 30) {
        font-size: $font-size-large - 4;
    }
    @include max-mq ($xs) {
        font-size: $font-size-large - 5;
    }
}
.terms-wrapper {
    padding: 101px 15px 102px;
    @include max-mq ($lg) {
        padding: 70px 15px 50px;
    }
    @include max-mq ($md - 30) {
        padding-bottom: 28px;
        padding-top: 120px;
    }
    @include max-mq ($sm) {
        padding-top: 140px;
    }
    @include max-mq ($sm - 1) {
        padding-top: 50px;
    }
    ul {
        padding-left: 0;
        width: 100%;
        text-align: center;
        li {
            display: inline-block;
            padding: 0 13px;
            vertical-align: middle;
            @include max-mq ($sm - 1) {
                padding: 0 5px;
            }
            @include max-mq ($mb_l) {
                padding: 0 1px;
            }
            @include max-mq ($mb_l) {
                width: 49%;
                padding-bottom: 15px;
            }
            &.active {
                a {
                    background-color: $orange_footer_title;
                    color: $main_white;
                    padding-left: 27px;
                    padding-right: 27px;
                    @include max-mq ($sm - 1) {
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                    @include max-mq ($mb_l) {
                        padding-left: 6px;
                        padding-right: 6px;
                    }
                }
            }
            a {
                background-color: $main_white;
                border-radius: 30px;
                border: 2px solid $orange_footer_title;
                display: block;
                color: $orange_footer_title;
                font-size: $font-size-small;
                font-weight: $bold;
                min-width: 148px;
                padding: 13px 9px;
                transition: all .5s;
                @include max-mq ($sm - 1) {
                    min-width: 100px;
                }
                @include max-mq ($mb_l) {
                    padding: 10px 5px;
                }
                &:hover {
                    background-color: $orange_footer_title;
                    color: $main_white;
                    
                }
            }
        }
    }
}
.view-stock-cpt-posts-items {
    .blog-navigation {
        padding-right: 48px;
    }
}