%gradient-top-bottom {
    background-image: -ms-linear-gradient(bottom, #EF9C1F 0%, #EE4922 100%);
    background-image: -moz-linear-gradient(bottom, #EF9C1F 0%, #EE4922 100%);
    background-image: -o-linear-gradient(bottom, #EF9C1F 0%, #EE4922 100%);
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #EF9C1F), color-stop(100, #EE4922));
    background-image: -webkit-linear-gradient(bottom, #EF9C1F 0%, #EE4922 100%);
    background-image: linear-gradient(to top, #EF9C1F 0%, #EE4922 100%);
}
%gradient-top-bottom-right {
    background-image: -ms-linear-gradient(top left, #EF8408 0%, #EE4C22 100%);
    background-image: -moz-linear-gradient(top left, #EF8408 0%, #EE4C22 100%);
    background-image: -o-linear-gradient(top left, #EF8408 0%, #EE4C22 100%);
    background-image: -webkit-gradient(linear, left top, right bottom, color-stop(0, #EF8408), color-stop(100, #EE4C22));
    background-image: -webkit-linear-gradient(top left, #EF8408 0%, #EE4C22 100%);
    background-image: linear-gradient(to bottom right, #EF8408 0%, #EE4C22 100%);
}
%box-shadow {
    box-shadow: 0px 0px 14px 0px rgba($color: $main_dark, $alpha: 0.12);
}
%box-shadow-light {
    box-shadow: 0px 10px 14px 0px rgba($color: $main_dark, $alpha: 0.05);
}
%box-shadow-light-hover {
    box-shadow: 0px 10px 14px 0px rgba($color: $main_dark, $alpha: 0.12);
}
%margin-auto-positioning {
    margin-left: auto;
    margin-right: auto;
}
%center-auto-positioning {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
}
%flex-center {
    align-items: center;
    display: flex;
}
%left-calc {
    padding-left: calc(50vw - ( #{$container_width} / 2 ) + 37px);
    width: 100%;
    @include max-mq ($lg) {
        padding-left: 37px;
    }
    @include max-mq ($md) {
        padding-left: 15px;
    }
}