.main-navigation-header {
    ul {
        li {
            a {
                color: $grey_menu;
                font-size: $font-size-large;
                @include max-mq ($lg) {
                    font-size: $font-size-large - 2;
                }
                &:hover {
                    color: $orange_button;
                }
            }
        }
    }
}
.main-navigation-header,
.main-navigation {
  ul {
    list-style: none;
    margin: 0;
    padding-left: 0;

    li:hover > ul,
    li.focus > ul {
      left: auto;
    }
  }

  li {
    float: left;
    position: relative;
    padding: 0 26px;
    @include max-mq ($ls) {
        padding: 0 21px;
    }
    @include max-mq ($lg) {
        padding: 0 11px;
    }
  }

  a {
    display: block;
    text-decoration: none;
  }

  ul ul {
    background-color: $main_white;
    float: left;
    left: -999em;
    position: absolute;
    top: auto;
    z-index: 99999;

    li:hover > ul,
    li.focus > ul {
      left: 100%;
    }
  }

  ul ul ul {
    left: -999em;
    top: 0;
  }

  ul ul a {
    width: 200px;
  }
}

/*--------------------------------------------------------------
# Responsive Menu
--------------------------------------------------------------*/

#site-navigation {
    position: relative;
    .hamburger {
        position: relative;
        z-index: 99999;
    }

> div {
    // background-color: $main_white;
    @extend %gradient-top-bottom-right;
    height: 100%;
    padding-bottom: 120px;
    padding-top: 120px;
    position: fixed;
    transition: all .5s;
    width: 40%;
    z-index: 999;
    @include max-mq ($ltl) {
        padding-bottom: 100px;
        padding-top: 100px;
    }
    @include max-mq ($lg) {
        padding-bottom: 70px;
        padding-top: 70px;
    }
    .nav-menu {
        .menu-item {
            float: left;
            text-align: center;
            width: 100%;
            padding: 0 10px 0 15px;
            a {
                color: $main_white;
                font-size: $mobile_menu_font_size - 5;
                @include max-mq ($mb_l) {
                    font-size: $mobile_menu_font_size - 11;
                }
            }
            .sub-menu {
                a {
                    font-size: $mobile_menu_font_size - 17;
                    @include max-mq ($mb_l) {
                        font-size: $mobile_menu_font_size - 20;
                    }
                }
            }
        }
    }
}
.desktop-hidden {
    @include min-mq ($mb_l + 1) {
        display: none !important;
    }
}
.hamburger {
    .hamburger-inner {
        &,
        &::before,
        &::after {
            transition: all .5s;
        }
    }
    &:hover {
        .hamburger-inner {
            &,
            &::before,
            &::after {
                background-color: $grey_menu;
            }
        }
    }
}

.header-phone-menu-popup {
    display: block;
    padding-top: 35px;
    text-align: center;
    font-size: $mobile_menu_font_size - 12;
    @include max-mq ($mb_l) {
        font-size: $mobile_menu_font_size - 19;
    }
}

&.toggled {

    .menu-toggle {

    .fa {

        &:before {
        content: "\f00d";
        }
    }
    }
}

// Animations
&.anim-left {

    > div {
    left: -100%;
    top: 0;
    bottom: 0;
    }

    &.toggled > div {
    left: 0;
    }
}

&.anim-right {

    > div {
    bottom: 0;
    right: -100%;
    top: 0;
    }

    &.toggled > div {
    right: 0;
    }

    .menu-toggle {
    position: relative;
    z-index: 9999;
    }
}

&.anim-top {

    > div {
    height: auto;
    left: auto;
    position: fixed;
    height: 100%;
    right: 0;
    top: -100%;
    opacity: 0;
    visibility: hidden;
    width: 100vw;
    @include max-mq ($mac) {
        overflow-y: scroll;
    }
    }

    &.toggled > div {
        opacity: 1;
        top: 0;
        visibility: visible;
    }
}

&.anim-popup {

    > div {
    bottom: 0;
    height: 100vh;
    left: 0;
    opacity: 0;
    right: 0;
    top: 0;
    visibility: hidden;
    width: 100%;
    }

    &.toggled > div {
    opacity: 1;
    visibility: visible;
    }

    .menu-toggle {
    position: relative;
    z-index: 9999;
    }
}

ul {

    &.nav-menu {

    li {
        margin-left: 0;
        margin-right: 0;

        &.menu-item-has-children {
            transition: all .5s;

        &.expandeds {

            > .arrow-toggle {

            > .fa {

                &:before {
                content: "\f106";
                }
            }
            }
        }
        }

        &.menu-item {

            .arrow-toggle {
                color: $main_dark;
                cursor: pointer;
                display: inline-block;
                font-size: 23px;
                height: 100%;
                margin-right: 0;
                padding: 1px 21px;
                position: absolute;
                z-index: 99;
                right: 0;
                top: 0;
            }
        }
    }
    }

    ul {

        &.sub-menu {
            background-color: transparent;
            // display: none;
            width: 100%;
            margin-left: 0;
            padding-top: 0;
            top: 0;
            left: 0;
            position: relative;
            a {
                font-size: $mobile_menu_font_size - 10;
                width: 100%;
            }
        }
    }
}
}

.menu-toggle {
  display: block;
  background-color: transparent;
  border: none;
  color: $main_dark;
  float: right;
  font-size: 28px;
  padding: 3px 2px;

//   @include max-mq($lg) {
//     display: block !important;
//   }
}
