.contact-twitter-section-wrapper {
    padding-top: 71px;
    @include max-mq ($sm - 1) {
        padding-top: 10px;
    }
    * {
        color: $main_white;
    }
    .contact-twitter-section-inner {
        margin-bottom: -50px;
        padding: 46px 15px 70px;
        position: relative;
        z-index: 9;
        @include max-mq ($sm - 1) {
            padding-left: 0;
            padding-right: 0;
        }
        &:before {
            @extend %gradient-top-bottom-right;
            border-radius: 0 50px 50px 0;
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            top: 0;
            height: 100%;
            width: 54.5%;
            z-index: -1;
            @include max-mq ($lg) {
                width: 49.5%;
            }
            @include max-mq ($sm - 1) {
                display: none;
                height: 0;
                width: 0;
            }
        }
    }
    .contact-form-wrap {
        flex: 0 0 50%;
        max-width: 50%;
        padding-left: 52px;
        padding-right: 11px;
        &:before {
            @extend %gradient-top-bottom-right;
            border-radius: 0 50px 50px 0;
            bottom: 0;
            content: '';
            display: none;
            height: 0;
            left: 0;
            position: absolute;
            top: 0;
            width: 0;
            z-index: -1;
            @include max-mq ($sm - 1) {
                height: 100%;
                width: 100%;
                display: block;
            }
        }
        @include max-mq ($lg) {
            padding-right: 45px;
            padding-left: 15px;
        }
        @include max-mq ($tab) {
            padding-right: 28px;
            padding-left: 5px;
        }
        @include max-mq ($sm - 1) {
            flex: 0 0 100%;
            max-width: 100%;
            padding-left: 30px;
            padding-right: 35px;
            position: relative;
            z-index: 9;
            
        }
    }
    
}
.text-form-section-description {
    max-width: 100%;
    padding: 41px 2px 34px;
    width: 100%;
    @include max-mq ($ip) {
        padding: 11px 2px 51px;
    }
}