.manufacturers-section {
    position: relative;
    .manufacturers-section-inner {
        @include max-mq ($lg) {
            position: relative;
            z-index: 9;
        }
    }
    .elipse-decoration-element {
        position: absolute;
        left: -279px;
        height: 599px;
        width: 599px;
        top: 10px;
        z-index: 9;
        @include max-mq ($mac) {
            left: -240px;
            height: 480px;
            width: 480px;
        }
        @include max-mq ($ls) {
            left: -200px;
            height: 400px;
            width: 400px;
        }
        @include max-mq ($lg) {
            left: -140px;
            height: 280px;
            width: 280px;
        }
    }
}
.manufacturers-section-wrapper {
    padding-bottom: 69px;
    padding-top: 69px;
    @include max-mq ($sm - 1) {
        padding-bottom: 0;
        padding-top: 45px;
    }
    @include max-mq ($mb_l) {
        padding-top: 20px;
    }
}
.manufacturers-section-description {
    @extend %center-auto-positioning;
    max-width: 628px;
    padding-bottom: 25px;
}
.main-section-heading {
    margin-bottom: 0.7em;
    font-size: $font-size-h1;
    font-weight: $bold;
    @include max-mq ($ls) {
        font-size: $font-size-h2 - 1;
    }
    @include max-mq ($mob) {
        font-size: $font-size-h2 - 4;
    }
    @include max-mq ($xs) {
        font-size: $font-size-h2 - 5;
    }
}
.manufacturers-items {
    padding-top: 15px;
}
.manufacturers-items-inner {
    @extend %center-auto-positioning;
    max-width: 896px;
    .manufacturers-image-link-wrap {
        padding: 0 31px;
        @include max-mq ($ip) {
            padding: 0 10px;
        }
        &.manufacturers-image-link-wrap-1 {
            .manufacturers-image-link-item {
                max-width: 190px;
            }
        }
        &.manufacturers-image-link-wrap-2 {
            .manufacturers-image-link-inner {
                @include max-mq ($mb_l) {
                    margin-bottom: 15px;
                }
            }
        }
        .manufacturers-image-link-item {
            max-width: 290px;
            @extend %margin-auto-positioning;
        }
        a {
            &:hover {
                @extend %box-shadow-light-hover;        
            }
        }
    }
    .manufacturers-image-link-inner {
        @extend %box-shadow-light;
        // align-items: center;
        align-items: flex-end;
        border-radius: 20px;
        display: flex;
        height: 100%;
        justify-content: center;
        // padding: 24px 20px 53px;
        padding: 24px 20px 44px;
        width: 100%;
        @include max-mq ($sm - 1) {
            margin-bottom: 55px;
        }
        .manufacturers-image-link-item {
            display: block;
            width: 100%;
        }
        a {
            display: block;
            width: 100%;
        }
        img {
            display: block;
            width: auto;
        }
    }
}
.manufacturers-row {
    @include max-mq ($sm - 1) {
        display: block;
    }
}