.twitter-embeded-content-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 13%;
    padding-top: 13px;
    @include max-mq ($lg) {
        padding-left: 25px;
    }
    @include max-mq ($tab) {
        padding-left: 14px;
    }
    @include max-mq ($sm - 1) {
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 15px;
        padding-right: 23px;
    }
    .twitter-bird-icon {
        padding-bottom: 21px;
        @include max-mq ($ip) {
            padding-bottom: 5px;
            padding-top: 30px;
        }
        i {
            font-size: $mobile_menu_font_size - 5;
            &::before {
                color: $twitter_blue;
            }
        }
        
    }
    .twitter-embeded-content-items {
        div {
            color: $gray_paragraph;
            a {
                color: $twitter_blue;
                &:hover {
                    color: $orange_footer_title;
                }
            }
        }
    }
}
.twitter-embeded-content-item {
    align-items: center;
    border-bottom: 1px solid $grey_twitter_border;
    display: flex;
    margin-bottom: -4px;
    padding-bottom: 21px;
    padding-top: 23px;
    width: 100%;
    &:last-child {
        border-bottom: none;
    }
}
.twitter-featured-image {
    flex: 0 0 17.5%;
    max-width: 17.5%;
    img {
        border-radius: 50%;
    }
}

.twitter-name-time {
    display: flex;
    justify-content: space-between;
    padding-bottom: 29px;
}
.twittter-user-name-excerpt-content-wrap {
    padding-left: 15px;
    flex: 0 0 82.5%;
    max-width: 82.5%;
}