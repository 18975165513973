div {
    input[type="text"],
    input[type="email"],
    input[type="url"],
    input[type="password"],
    input[type="search"],
    input[type="number"],
    input[type="tel"],
    input[type="range"],
    input[type="date"],
    input[type="month"],
    input[type="week"],
    input[type="time"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="color"],
    textarea {
      background-color: transparent;
      border-bottom: 1px solid $main_white;
      border-left: none;
      border-radius: 0;
      border-right: none;
      border-top: none;
      color: $main_white;
      font-family: $primary_font;
      font-size: $font-size-base;
      line-height: 1em;
      padding: 5px 9px;
      vertical-align: middle;
      width: 100%;
      .contact-addres-details-section-inner & {
        border-bottom: 1px solid $gray_paragraph;
      }
    }
    input[type="text"],
    input[type="email"],
    input[type="url"],
    input[type="password"],
    input[type="search"],
    input[type="number"],
    input[type="tel"],
    input[type="range"],
    input[type="date"],
    input[type="month"],
    input[type="week"],
    input[type="time"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="color"]
    select {
        height: 42px;
        @include max-mq ($mb_l) {
          height: 60px;
        }
    }
    textarea {
        padding-left: 0.5em;
        padding-top: 1em;
      resize: none;
      width: 100%;
      height: 103px;
    }
  }
  
  form input.frm_verify {
      display: none !important;
  }
  
  input[type="submit"],
  button[type="submit"] {
  
    &:hover {
    }
  }
  
  .frm_screen_reader {
      border: 0;
      clip: rect(1px, 1px, 1px, 1px);
      -webkit-clip-path: inset(50%);
      clip-path: inset(50%);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
      word-wrap: normal !important;
  }
  
  
  button,
  input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    color: $main_dark;
    border: 1px solid;
    border-radius: 0;
    font-family: $secondary_font;
    font-size: $font-size-base;
    line-height: 1.15em;
    padding: 10px;
    vertical-align: middle;
    
  }
  
  .frm_fields_container {
      &:after {
          content: '';
          display: block;
          clear: both;
      }
  }
  
  .frm_submit,
  .frm_full {
      float: left;
      width: 100%;
  }
  .frm_half {
      float: left;
      width: 47.3%;
      margin-left: 5.4%;
      @include max-mq($mb_p) {
        margin-left: 0;
        width: 100%;
      }
      &.frm_first {
          margin-left: 0;
      }
  }
  
  .frm_submit {
      text-align: center;
      padding-top: 25px;
  }
  
  .frm_required {
      display: none; 
  }
  
  .frm_screen_reader {
      opacity: 0;
  }
  
  .frm_form_fields  {
      fieldset {
          border: none;
          margin: 0;
          padding: 0;
      }
  }
  
  .frm_form_field {
      &.form-field {
          margin-bottom: 22px;
      }
  }
  .frm_forms {
    .frm_error {
        font-size: $font-size-small - 1;
        // color: $red_border;
        color: $grey_dark_heading;
    }
  }
  .contact-addres-details-section-inner {
    .frm_button_submit {
        &.frm_final_submit {
            background-color: $orange_button;
            border-color: $orange_button;
            color: $main_white;
            &:hover {
                background-color: $main_white;
                color: $orange_button;
            }
        }
    }
  }
  
  
  /*---- Placeholder ----*/
  ::-webkit-input-placeholder {
    opacity: 1;
    color: $main_white !important;
    .contact-addres-details-section-inner & {
        color: $gray_paragraph !important;
    }
  }
  \:-moz-placeholder {
    opacity: 1;
    color: $main_white !important;
    .contact-addres-details-section-inner & {
        color: $gray_paragraph !important;
    }
  }
  ::-moz-placeholder {
    opacity: 1;
    color: $main_white !important;
    .contact-addres-details-section-inner & {
        color: $gray_paragraph !important;
    }
  }
  \:-ms-input-placeholder {
    opacity: 1;
    color: $main_white !important;
    .contact-addres-details-section-inner & {
        color: $gray_paragraph !important;
    }
  }
  *::-webkit-input-placeholder {
    opacity: 1;
    color: $main_white !important;
    .contact-addres-details-section-inner & {
        color: $gray_paragraph !important;
    }
  }
  *:-moz-placeholder {
    opacity: 1;
    color: $main_white !important;
    .contact-addres-details-section-inner & {
        color: $gray_paragraph !important;
    }
  }
  *:-ms-input-placeholder {
    opacity: 1;
    color: $main_white !important;
    .contact-addres-details-section-inner & {
        color: $gray_paragraph !important;
    }
  }
  