.our-vision-image-wrap {
    position: relative;
    z-index: 9;
    .image-section-main-image {
        &.align-center {
            img {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}
.our-vision-wrapper {
    .our-wision-section-description {
        max-width: 618px;
    }
}