.header-logo-elipse-decoration {
    left: -290px;
    position: absolute;
    top: -390px;
    width: 610px;
    z-index: -1;
    @include max-mq ($ls) {
        left: -180px;
        top: -270px;
        width: 410px;
    }
    @include max-mq ($sm - 1) {
        left: -160px;
        top: -250px;
        width: 340px;
    }
    @include max-mq ($mb) {
        left: -140px;
        top: -200px;
        width: 280px;
    }
}