.breadcrumbs {

  p {
  }

  a {

    &:hover {
    }
  }

  .breadcrumb_last {
  }
}

.woocommerce .woocommerce-breadcrumb {

  p {
    margin: 0;
  }

  a {

    &:hover {
    }
  }

  span {
  }
}
