.latest-posts-section-wrapper {
    padding: 10px 0 55px;
    @include max-mq ($ip) {
        padding-top: 23px;
    }
}
.latest-posts-section-inner {
    position: relative;
    @extend %left-calc;
    &:after {
        clear: both;
        content: '';
        display: block;
    }
    .blog-heading-position {
        float: left;
        max-width: 29%;
        width: 100%;
        @include max-mq ($sm - 1) {
            max-width: 100%;
        }
    }
    .blog-heading {
        padding-left: 17px;
        padding-top: 64px;
        @include max-mq ($md) {
            padding-left: 0;
        }
        @include max-mq ($sm - 1) {
            padding-top: 0;
        }
        .main-section-title {
            line-height: 1;
        }
    }
    .latest-post-items-wrapper {
        float: right;
        max-width: 71%;
        overflow: hidden;
        width: 100%;
        @include max-mq ($sm - 1) {
            max-width: 100%;
        }
        
    }
    .latest-posts-items {
        float: right;
        width: 100%;
    }
}
.latest-posts-slider {
    .latest-post {
        max-width: 400px;
        @include max-mq ($sm - 1) {
            max-width: 320px;
        }
        @include max-mq ($mob + 20) {
            max-width: 300px;
        }
        @include max-mq ($xs) {
            max-width: 270px;
        }
    }
}
.latest-post {
    .latest-posts-content-wrapper {
        padding: 0 20px 0 10px;
        background-color: $main_white;
        @include max-mq ($sm - 1) {
            padding-left: 0;
        }
        @include max-mq ($xs) {
            padding-right: 15px;
        }
    }
    .latest-posts-content-inner {
        @extend %box-shadow-light;
        border-radius: 20px;
        transition: all .5s;
        &:hover {
            @extend %box-shadow-light-hover;
            // .latest-posts-thumnail-wrapper {
            //     img {
            //         opacity: 1;
            //     }
            // }
            .latest-post-title {
                color: $orange_button;
            }
            .latest-post-date-value {
                color: $orange_button;
            }
            p {
                color: $orange_button;
            }
        }
    }
    .latest-posts-thumnail-wrapper {
        background-color: $red_posts;
        border-radius: 20px 20px 0 0;
        a,
        img {
            display: block;
        }
        img {
            border-radius: 20px 20px 0 0;
            opacity: 0.77;
            transition: all .5s;
            width: 100%;
        }
    }
    .latest-post-title-text-description-wrap-inner {
        padding: 20px;
    }
    .latest-post-title {
        font-size: $font-size-h3;
        font-weight: $bold;
        margin: 0 0 0.2em;
        line-height: 1.2;
        transition: all .5s;
        @include max-mq ($lg) {
            font-size: $font-size-h3 - 3;
        }
    }
    .latest-post-date-value {
        color: $red_posts;
        font-size: $font-size-small;
        transition: all .5s;
    }
    .latest-post-excerpt {
        padding-top: 7px;
        p {
            margin-bottom: 0.2em;
        }
    }
}
