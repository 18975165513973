.button {
    border-radius: 30px;
    border: 1px solid;
    box-shadow: 0px 0px 25px 0px rgba($color: $main_dark, $alpha: 0.15);
    font-size: $font-size-small;
    font-weight: $bold;
    transition: all .5s;
    &.button-orange {
        background-color: $orange_button;
        border-color: $orange_button;
        color: $main_white;
        padding: 15px 39px;
        &:hover {
            background-color: $main_white;
            color: $orange_button;
        }
    }
    &.button-white {
        background-color: $main_white;
        border-color: $main_white;
        color: $orange_button;
        padding: 15px 47px;
        &:hover {
            background-color: $orange_button;
            color: $main_white;
        }
    }
}