
.hero-section-wrapper {
    padding-bottom: 175px;
    padding-top: 66px;
    @include max-mq ($sm - 1) {
        padding-bottom: 35px;
        padding-top: 62px;
    }
    @include max-mq ($mb_l) {
        padding-bottom: 35px;
        padding-top: 53px;
    }
    .hero-container {
        max-width: 1308px;
    }
    .hero-row {
        align-items: center;
    }
}
.text-image-section-description-button-wrap {
    @include max-mq ($sm - 1) {
        padding-bottom: 55px;
    }
}
.image-section-main-image {
    @include max-mq ($sm - 1) {
        img {
            width: 100%;
        }
    }
}
.hero-section-main-image-wrap {
    img {
        display: block;
    }
    @include min-mq ($ls + 20) {
        padding-right: 68px;
        img {
            display: block;
            width: 100%;
        }
    }
}
.hero-section-text-description {
    padding-right: 25px;
    h1, h2 {
        font-size: $font-size-h1 + 10;
        font-weight: $bold;
        letter-spacing: 1px;
        line-height: $h1-line;
        margin-bottom: 0.65em;
        margin-top: 0.2em;
        @include max-mq ($ls) {
            font-size: $font-size-h1 - 2;
        }
        @include max-mq ($lg) {
            font-size: $font-size-h1 - 10;
        }
        @include max-mq ($mob) {
            font-size: $font-size-h1 - 16;
        }
    }
    p {
        font-size: $font-size-large;
        line-height: 1.65;
    }
}
.text-image-link-wrap {
    padding-top: 33px;
    @include max-mq ($ip) {
        padding-top: 15px;
    }
}