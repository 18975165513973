.site-footer {
    background-color: $grey_footer;
    position: relative;
    padding-top: 68px;
    color: $grey_footer_content;
    @include max-mq ($mob + 20) {
        font-size: $font-size-base - 2;
    }
    * {
        line-height: 1.9;
    }
    .widget-wrapper {
        padding-bottom: 17px;
    }
    .footer-column-1 {
        padding: 95px 15px 15px 46px;
        flex: 0 0 55.5%;
        max-width: 55.5%;
        @include max-mq ($lg) {
            flex: 0 0 44%;
            max-width: 44%;
        }
        @include max-mq ($tab) {
            flex: 0 0 38%;
            max-width: 38%;
            padding-left: 35px;
        }
        @include max-mq ($sm - 1) {
            flex: 0 0 100%;
            max-width: 100%;
            padding-left: 15px;
            padding-top: 20px;
            text-align: center;
        }
        .widget_media_image {
            max-width: 263px;
            width: 100%;
            @include max-mq ($sm - 1) {
                margin-left: auto;
                margin-right: auto;
            }
        }
        img {
            width: 100%;
        }
    }
    .footer-column-2 {
        flex: 0 0 18%;
        max-width: 18%;
        @include max-mq ($lg) {
            flex: 0 0 20%;
            max-width: 20%;
        }
        @include max-mq ($tab) {
            flex: 0 0 23%;
            max-width: 23%;
        }
        @include max-mq ($sm - 1) {
            flex: 0 0 33%;
            max-width: 33%;
        }
        @include max-mq ($xs) {
            flex: 0 0 100%;
            max-width: 100%;
            text-align: center;
        }
    }
    .footer-column-3 {
        flex: 0 0 26.5%;
        max-width: 26.5%;
        @include max-mq ($lg) {
            flex: 0 0 30%;
            max-width: 30%;
        }
        @include max-mq ($lg) {
            flex: 0 0 36%;
            max-width: 36%;
        }
        @include max-mq ($sm - 1) {
            flex: 0 0 67%;
            max-width: 67%;
        }
        @include max-mq ($xs) {
            flex: 0 0 100%;
            max-width: 100%;
            text-align: center;
        }
    }
    a {
        color: $grey_footer_content;
        &:hover {
            color: $orange_footer_title;    
        }
    }
    .widget-title {
        color: $orange_footer_title;
        font-size: $font-size-large;
        font-weight: $bold;
        text-transform: uppercase;
        line-height: 1.1;
        @include max-mq ($mob + 20) {
            font-size: $font-size-large - 2;
        }
    }
    .sub-menu {
        display: none;
    }

}
.footer-copyright {
    border-top: 1px solid $grey_footer_border;
    margin-top: 10px;
    padding: 28px 10px;
    font-size: $font-size-small - 2;
     > span {
        display: inline-block;
        vertical-align: middle;
     }
}
.footer-social-links-wrap {
    > div {
        display: inline-block;
        vertical-align: middle;
        margin: 0 5px;
    }
}