.animation-items-section-wrapper {
    padding-top: 9px;
    @include max-mq ($sm - 1) {
        padding-top: 0;
    }
}
.animation-items-section-inner {
    display: flex;
    align-items: center;
    @include max-mq ($sm - 1) {
        display: block;
    }
    .animation-items-main-image-wrapper {
        flex: 0 0 56%;
        max-width: 56%;
        @include max-mq ($sm - 1) {
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
            padding-bottom: 22px;
            padding-top: 22px;
            overflow: hidden;
        }
    }
    .animation-heading-text-button-wrap {
        flex: 0 0 44%;
        max-width: 44%;
        padding: 0 15px 45px;
        @include max-mq ($sm - 1) {
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
        }
        @include max-mq ($sm - 1) {
            padding-bottom: 25px;
        }
    }
    .animation-heading-text-button-content-position {
        max-width: 440px;
        margin-left: 0;
        margin-right: auto;
        @include max-mq ($sm - 1) {
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
    }
}

.animation-items-main-image-position-inner {
    .animation-items-main-image-item {
        height: 163px;
        position: relative;
        width: 163px;
        @include max-mq ($mac) {
            height: 129px;
            width: 129px;
        }
        @include max-mq ($lg) {
            height: 90px;
            width: 90px;
        }
        @include max-mq ($md) {
            height: 76px;
            width: 76px;
        }
        @include max-mq ($ip) {
            height: 64px;
            width: 64px;
        }
        @include max-mq ($mob - 5) {
            height: 50px;
            width: 50px;
        }
    }
    img {
        // @extend %box-shadow;
        animation-duration: 25s;
        animation-iteration-count: infinite;
        animation-name: rotate_img_back;
        animation-timing-function: linear;
        border-radius: 50%;
        bottom: 0;
        display: block;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
      }
  }
.animation-items-main-image-inner {
    height: 751px;
    margin: auto;
    position: relative;
    width: 751px;
    @include max-mq ($mac) {
        height: 631px;
        width: 631px;
    }
    @include max-mq ($lg) {
        height: 451px;
        width: 451px;
    }
    @include max-mq ($md) {
        height: 380px;
        width: 380px;
    }
    @include max-mq ($ip) {
        height: 320px;
        width: 320px;
    }
    @include max-mq ($mob - 5) {
        height: 250px;
        width: 250px;
    }
}
.animation-items-main-image-position {
    animation-duration: 25s;
    animation-iteration-count: infinite;
    animation-name: rotate_img;
    animation-timing-function: linear;
    border-radius: 50%;
    border: 2px solid rgba($color: $red_border, $alpha: 1.0);
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    &.animation-item-6 {
        animation: none;
        img {
            animation: none;
        }
    }  
}
.animation-item-1 {
    border: 2px solid rgba($color: $red_border, $alpha: 0.2);
    width: 100%;
    height: 100%;
    .animation-items-main-image-item {
        right: 55px;
        top: 165px;
        @include max-mq ($lg) {
            right: 25px;
            top: 95px;
        }
    }
}
.animation-item-2 {
    border: none;
    width: 100%;
    height: 100%;
    .animation-items-main-image-item {
        left: 511px;
        top: 614px;
        @include max-mq ($mac) {
            left: 451px;
            top: 493px;
        }
        @include max-mq ($lg) {
            left: 385px;
            top: 273px;
        }
        @include max-mq ($md) {
            left: 311px;
            top: 253px;
        }
        @include max-mq ($ip) {
            left: 221px;
        }
        @include max-mq ($mob - 5) {
            left: 191px;
            top: 182px;
        }
    }
}
.animation-item-3 {
    border: 2px solid rgba($color: $red_border, $alpha: 0.4);
    width: 80%;
    height: 80%;
    @include max-mq ($ip) {
        width: 77%;
        height: 77%;
    }
    .animation-items-main-image-item {
        bottom: 89px;
        left: 411px;
        @include max-mq ($mac) {
            bottom: 76px;
            left: 371px;
        }
        @include max-mq ($lg) {
            bottom: 26px;
            left: 301px;
        }
        @include max-mq ($md) {
            bottom: 26px;
            left: 233px;
        }
        @include max-mq ($ip) {
            left: 193px;
        }
        @include max-mq ($mob - 5) {
            left: 143px;
        }
    }
}
.animation-item-4 {
    border: 2px solid rgba($color: $red_border, $alpha: 0.6);
    width: 60%;
    height: 60%;
    @include max-mq ($ip) {
        width: 56%;
        height: 56%;
    }
    .animation-items-main-image-item {
        left: 14px;
        top: 337px;
        @include max-mq ($mac) {
            left: 0;
            top: 265px;
        }
        @include max-mq ($lg) {
            left: 0;
            top: 195px;
        }
        @include max-mq ($md) {
            left: 0;
            top: 161px;
        }
        @include max-mq ($ip) {
            top: 121px;
        }
        @include max-mq ($mob - 5) {
            top: 95px;
        }
    }
}
.animation-item-5 {
    width: 40%;
    height: 40%;
    @include max-mq ($ip) {
        width: 34%;
        height: 34%;
    }
    .animation-items-main-image-item {
        left: 280px;
        top: 34px;
        @include max-mq ($mac) {
            left: 228px;
            top: 14px;
        }
        @include max-mq ($lg) {
            left: 168px;
            top: 14px;
        }
        @include max-mq ($md) {
            left: 141px;
            top: 14px;
        }
        @include max-mq ($ip) {
            left: 103px;
        }
        @include max-mq ($mob - 5) {
            left: 80px;
        }
    }
}
.animation-item-6 {
    border: none;
    width: 20%;
    height: 20%;
}
.animation-items-section-description {
    padding-top: 7px;
    p {
        margin-bottom: 1.5em;
    }
}

.animation-items-link-wrap {
    padding-top: 10px;
}
@keyframes rotate_img {
    from  {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
  
@keyframes rotate_img_back {
    from  {
        transform: rotate(0);
    }
    to {
        transform: rotate(-360deg);
    }
}

  