.slick-slider {
}

.slick-arrow {
  background-color: transparent;
  border: none;
  bottom: 0;
  color: $main_dark;
  font-size: 30px;
  height: 35px;
  line-height: 35px;
  margin: auto 0;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 35px;
  z-index: 10;

  &.slick-prev-lf {
    left: 0;
  }

  &.slick-next-lf {
    right: 0;
  }
}

.slick-dots {
  margin: 20px 0 0;
  padding-left: 0;
  text-align: center;

  li {
    display: inline-block;
    margin: 0 5px;
    vertical-align: middle;

    button {
      border-radius: 50%;
      background-color: rgba($main_dark, 0.4);
      border: 1px solid;
      color: transparent;
      font-size: 0;
      height: 15px;
      padding: 0;
      width: 15px;
    }

    &.slick-active {

      button {
        background-color: $main_dark;
      }
    }
  }
}
