.featured-post-item-wrapper {
    padding-bottom: 73px;
    padding-top: 147px;
    @include max-mq ($lt) {
        padding-left: 15px;
        padding-right: 15px;
    }
    @include max-mq ($ls) {
        padding-bottom: 65px;
        padding-top: 95px;
    }
    @include max-mq ($lg) {
        padding-top: 15px;
    }
    @include max-mq ($ipad) {
        padding-top: 45px;
    }
    @include max-mq ($mb) {
        padding-bottom: 45px;
    }
}
.featured-post-item-inner {
    width: 100%;
}
.featured-post-container {
    max-width: $container_width + 32;
}
.featured-post-wrapper {
    &:hover {
        .featured-posts-content-inner {
            .featured-post-title {
                color: $orange_button;
            }
        }
    }
}
.featured-posts-content-inner {
    background-color: $grey_featured_bg;
    border-radius: 17px;
    padding: 42px 55px 24px 100px;
    @include max-mq ($lg) {
        padding: 42px 55px 35px 60px;
    }
    @include max-mq ($md) {
        padding: 32px 30px 10px 30px;
    }
    @include max-mq ($sm - 1) {
        padding: 0;
    }
    .featured-posts-thumnail-wrapper {
        float: right;
        width: 36.7%;
        @include max-mq ($sm - 1) {
            text-align: center;
            width: 100%;
        }
        img {
            border-radius: 17px;
            display: block;
            width: 100%;
            @include max-mq ($sm - 1) {
                border-radius: 17px 17px 0 0;
            }
        }
    }
    .featured-blog-title-excerpt-wrapper {
        float: left;
        width: 63.3%;
        padding-right: 25px;
        @include max-mq ($sm - 1) {
            padding: 25px 25px 12px;
            width: 100%;
        }
    }
    .featured-post-heading {
        max-width: 560px;
    }
    .featured-post-title {
        font-weight: $bold;
        font-size: $font-size-h2 + 1;
        margin-top: 0.25em;
        line-height: 1.2;
        margin-bottom: 0.75em;
        transition: color .5s;
        @include max-mq ($lg) {
            font-size: $font-size-h2 - 5;
        }
        @include max-mq ($md) {
            font-size: $font-size-h2 - 10;
        }
    }
    .featured-post-date {
        color: $orange_footer_title;
        font-size: $font-size-h3 - 2;
        @include max-mq ($lg) {
            font-size: $font-size-h3 - 5;
        }
        @include max-mq ($md) {
            font-size: $font-size-h3 - 6;
        }
        i {
            font-size: $font-size-h2 - 6;
            @include max-mq ($lg) {
                font-size: $font-size-h2 - 10;
            }
            @include max-mq ($md) {
                font-size: $font-size-h2 - 12;
            }
        }
    }
    .featured-post-date-value {
        letter-spacing: -0.3px;
        padding-left: 9px;
        position: relative;
        top: -4px;
    }
    .featured-post-excerpt {
        font-size: $font-size-h3;
        letter-spacing: 0.3px;
        max-width: 580px;
        padding-top: 14px;
        width: 100%;
        @include max-mq ($lg) {
            font-size: $font-size-h3 - 3;
        }
        @include max-mq ($md) {
            font-size: $font-size-h3 - 5;
        }
        @include max-mq ($sm - 1) {
            max-width: 100%;
        }
    }
}
.blog-navigation {
    padding-right: 28px;
    padding-top: 3px;
    text-align: right;
    .page-numbers {
        font-size: $font-size-h3;
        color: $orange_footer_title;
        transition: color 0.5s;
        &:hover {
            color: $grey_footer_content;
        }
    }
}
.blog-page-latest-posts-content-wrapper {
    margin-top: 0;
    position: relative;
    padding-bottom: 63px;
    padding-top: 74px;
    @include max-mq ($lg) {
        padding-bottom: 35px;
    }
    @include max-mq ($mb) {
        padding-top: 64px;
        padding-bottom: 10px;
    }
    &:before {
        background-color: $gray_paragraph;
        bottom: auto;
        content: '';
        height: 1px;
        left: 0;
        margin: auto;
        max-width: 267px;
        position: absolute;
        right: 0;
        top: 0;
    }
}
.blog-page-post-wrapper {
    transition: none;
}
.blog-page-latest-posts-content-inner {
    @extend %center-auto-positioning;
    max-width: 884px;
    width: 100%;
    .blog-page-post {
        &:last-of-type {
            .blog-page-posts-content-inner {
                margin-bottom: 37px;
            }
        }
        &:nth-child(even) {
            .blog-page-posts-thumnail-wrapper {
                border-radius: 0 17px 17px 0;
                float: right;
                @include max-mq ($sm - 1) {
                    border-radius: 17px 17px 0 0;
                }
                img {
                    border-radius: 0 17px 17px 0;
                    @include max-mq ($sm - 1) {
                        border-radius: 17px 17px 0 0;
                    }
                }
            }
            .blog-page-blog-title-excerpt-wrapper {
                float: left;
                padding-left: 51px;
                @include max-mq ($lg) {
                    padding-left: 35px;
                }
                @include max-mq ($md) {
                    padding-left: 25px;
                }
                @include max-mq ($ip) {
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }
        }
        
    }
    .blog-page-posts-content-inner {
        @extend %box-shadow;
        background-color: $grey_featured_bg;
        border-radius: 16px;
        margin-bottom: 52px;
        position: relative;
        transition: background-color 0.5s;
        &:hover {
            background-color: transparent;
            .blog-page-post-date,
            .blog-page-post-excerpt,
            .blog-page-post-title {
                color: $main_white;
            }
        }
        &:before {
            @extend %gradient-top-bottom;
            border-radius: 17px;
            bottom: 0;
            content: '';
            height: 100%;
            left: 0;
            margin: auto;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            z-index: -1;
        }
    }
    .blog-page-posts-thumnail-wrapper {
        background-color: $red_posts;
        border-radius: 17px 0 0 17px;
        float: left;
        width: 38.5%;
        @include max-mq ($sm - 1) {
            border-radius: 17px 17px 0 0;
            width: 100%;
        }
        img {
            border-radius: 17px 0 0 17px;
            display: block;
            width: 100%;
            opacity: 0.77;
            @include max-mq ($sm - 1) {
                border-radius: 17px 17px 0 0;
            }
        }
    }
    .blog-page-blog-title-excerpt-wrapper {
        padding: 15px 45px 13px 75px;
        float: right;
        width: 61.5%;
        @include max-mq ($lg) {
            padding-left: 45px;
        }
        @include max-mq ($md) {
            padding-left: 30px;
        }
        @include max-mq ($sm - 1) {
            padding-left: 25px;
            width: 100%;
        }
        @include max-mq ($ip) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    .blog-page-post-title-text-description-wrap {
        max-width: 380px;
        width: 100%;
        @include max-mq ($md) {
            max-width: 100%;
        }
    }
    .blog-page-post-heading {
        max-width: 340px;
        width: 100%;
        @include max-mq ($md) {
            max-width: 100%;
        }
    }
    .blog-page-post-title {
        color: $main_dark;
        font-size: $font-size-h3;
        font-weight: $bold;
        transition: color 0.5s;
        line-height: 1.2;
        margin-bottom: 0.55em;
        @include max-mq ($md) {
            font-size: $font-size-h3 - 2;
        }
    }
    .blog-page-post-date {
        font-size: $font-size-small;
        color: $orange_footer_title;
        transition: color 0.5s;
        i {
            font-size: $font-size-small + 3;
        }
    }
    .blog-page-post-date-value {
        padding-left: 6px;
    }
    .blog-page-post-excerpt {
        padding-top: 16px;
        p {
            transition: color 0.5s;
        }
    }

}
.page-numbers {
    display: none;
    &.next,
    &.prev {
        display: inline-block;
        vertical-align: middle;
        margin: 0 10px;
    }

}
