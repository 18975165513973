.about-us-text-image-section {
    h2 {
        font-size: $font-size-h2 - 6;
        font-weight: $bold;
        margin-bottom: 0.5em;
        margin-top: 1.356em;
    }
    p {
        margin-top: 0;
    }
}
.about-us-text-image-section {
    .text-image-section-wrapper {
        @include min-mq ($mac) {
            padding-bottom: 16px;
            padding-top: 64px;
        }
        .text-image-section-description {
            padding-bottom: 29px;
        }
    }
}