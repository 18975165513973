// Fonts Register
@import url('https://fonts.googleapis.com/css?family=DM+Sans:400,700&display=swap');


$main_background: #fff;

$general_link_color: #25292D;
$main_dark: #171717;
$main_darker: #000000;
$main_white: #fff;

// Colors:
$grey_footer_border: #DBDBDB;
$grey_footer: #F5F7F7;
$grey_twitter_border: #E4E4E4;
$grey_contact_form_bg: #F3F3F3;
$gray_paragraph: #777777;
$grey_dark_heading: #171717;
$grey_menu: #252525;
$grey_footer_content: #363636;
$grey_featured_bg: #F5F5F5;

$twitter_blue: #00ACEE;


$red_border: #FF0000;
$red_posts: #EE1C25;

$orange_button: #EF8705;
$orange_footer_title: #EF8706;

/*Font Variables*/
$primary_font: 'DM Sans', sans-serif;
$secondary_font: 'DM Sans', sans-serif;

//Font Weight
$reguular: 400;
$bold: 700;

//Font Size
$font-size-base: 16px;
$font-size-large: 18px;
$font-size-small: 14px;

$mobile_menu_font_size: 52px;
$hero_large_font_size: 60px;

$font-size-h1: 48px;
$h1-line: 1.25;
$font-size-h2: 36px;
$h2-line: 1.3;
$font-size-h3: 24px;
$h3-line: 1.3;
$font-size-h4: 20px;
$h4-line: 1.3;
$font-size-h5: 18px;
$h5-line: 1.3;
$font-size-h6: 15px;
$h6-line: 1.3;

// Breakpoints
$fhd: 1920px;
$ltl: 1660px;
$mac: 1440px;
$lt: 1366px;
$ls: 1280px;
$lg: 1200px;
$ipad: 1030px;
$md: 992px;
$tab: 800px;
$sm: 768px;
$mb_p: 650px;
$mb_l: 580px;
$mb: 480px;
$ip: 450px;
$mob: 360px;
$xs: 350px;

$container_width: 1280px;
