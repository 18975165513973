.contact-form-addres-details-row {
    align-items: center;
}
.contact-addres-email-phone-details-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
    @include max-mq ($sm - 1) {
        flex: 0 0 100%;
        max-width: 100%;
        padding-top: 25px;
    }
    .contact-addres-email-phone-details-inner {
        margin-left: auto;
        margin-right: 0;
        max-width: 412px;
        padding-right: 18px;
        @include max-mq ($sm - 1) {
            margin-left: auto;
            margin-right: auto;
            padding-right: 15px;
            padding-left: 15px;
        }
    }
    .contact-details-item {
        align-items: center;
        border-bottom: 1px solid $grey_twitter_border;
        display: flex;
        padding: 50px 15px;
        width: 100%;
        @include max-mq ($mb) {
            font-size: $font-size-base - 1;
        }
        &:last-child {
            border-bottom: none;
        }
    }
    .contact-details-icon {
        flex: 0 0 22%;
        max-width: 22%;
        @include max-mq ($mb) {
            flex: 0 0 21%;
            max-width: 21%;
        }
    }
    .contact-details-description {
        flex: 0 0 78%;
        max-width: 78%;
        padding-left: 5px;
        @include max-mq ($mb) {
            flex: 0 0 79%;
            max-width: 79%;
        }
    }

}

.contact-twitter-section-wrapper {
    &.contact-addres-details-section-wrapper {
        .contact-form-wrap {
            &:before {
                @include max-mq ($sm - 1) {
                    background-color: $grey_contact_form_bg;
                    background-image: none;
                }
            }
        }
    }
    .contact-twitter-section-inner {
        &.contact-addres-details-section-inner {
            margin-bottom: 0;
            @include max-mq ($sm - 1) {
                padding-bottom: 15px;
            }
            &:before {
                background-color: $grey_contact_form_bg;
                background-image: none;
            }
            .main-section-heading {
                color: $main_darker;
            }
        }
    }
    .contact-addres-email-phone-details-wrapper  {
        p {
            margin: 0;
        }
        *{
            color: $gray_paragraph;
        }
        a {
            color: $gray_paragraph;
            &:hover {
                color: $orange_button;
            }
        }
    }
}
.contact-addres-details-form-section {
    padding-bottom: 64px;
    @include max-mq ($sm - 1) {
        padding-bottom: 14px;
    }
}
.page-template-contact-us  {
    .contact-twitter-form-section {
        display: none;
    }
}
.acf-map {
	border: none;
	height: 530px;
	margin: 0;
    width: 100%;
    img {
        max-width: inherit !important;
    }
    @include max-mq ($sm - 1) {
        height: 450px;
    }
}
