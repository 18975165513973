body {
    position: relative;
    &:before {
        @extend %gradient-top-bottom-right;
        bottom: auto;
        content: '';
        display: block;
        // height: 88.7vh;
        height: 830px;
        left: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 42.8vw;
        border-radius: 0 0 0 50px;
        @include max-mq ($lg) {
            height: 750px;
        }
        @include max-mq ($sm - 1) {
            height: 119px;
        }
        @include max-mq ($mb_l) {
            height: 87px;
            width: 45.8vw;
        }
    }
}
.shadow-radius-image-styling {
    img {
        @extend %box-shadow;
        border-radius: 20px;
        display: block;
    }
}
.site-content {
    position: relative;
}
.widget_media_image {
    a {
        display: block;
    }
    img {
        display: block;
    }
}
.circle-decoration-element {
    width: 372px;
    position: absolute;
    right: 0;
    opacity: 0.5;
    @include max-mq ($ls) {
        width: 256px;
    }
    @include max-mq ($sm - 1) {
        width: 236px;
    }
    
}
.nav-menu {
    &:after {
        content: '';
        clear: both;
        display: block;
    }
}
.entry-title {
    font-size: $hero_large_font_size;
    font-weight: $bold;
    @include max-mq ($ls) {
        font-size: $font-size-h1;
    }
    @include max-mq ($lg) {
        font-size: $font-size-h1 - 8;
    }
    @include max-mq ($mob) {
        font-size: $font-size-h1 - 8;
    }
}