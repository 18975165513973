.hire-text-image-section {
    .main-section-heading {
        font-size: $font-size-h2 - 6;
        margin-bottom: 1em;
        @include max-mq ($ls) {
            font-size: $font-size-h2 - 8;
        }
    }
}
.service-text-image-section {
    padding: 70px 0;
    @include max-mq ($sm - 1) {
        padding: 0;
    } 
    &.text-image-section-right-gradient {
        &:before {
            width: 36.5vw;
            @include min-mq ($fhd + 20) {
                width: 39vw;
            }
        }
    }
    .elipse-decoration-element {
        background-image: url('../images/elipse-decoration-servicing.svg');
        background-repeat: no-repeat;
        background-position: bottom;
        bottom: -15px;
        height: 500px;
        left: auto;
        margin: auto;
        position: absolute;
        right: 0;
        right: 0;
        top: 15px;
        width: 313px;
        @include max-mq ($sm - 1) {
            height: 100%;
        }
    }
}
.text-image-section-right-gradient {
    position: relative;
    &:before {
        @extend %gradient-top-bottom-right;
        border-radius: 50px 0 0 50px;
        bottom: -10px;
        content: '';
        display: block;
        height: 500px;
        left: auto;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 26.1vw;
        z-index: -1;
        @include min-mq ($fhd + 20) {
            width: 36vw;
        }
        @include max-mq ($sm - 1) {
            display: none;
            height: 0;
            width: 0;
        }
    }
    .text-image-main-image-wrap {
        @include max-mq ($sm - 1) {
            position: relative;
            padding-bottom: 47px;
            padding-top: 47px;
            margin-bottom: 10px;
        }
        &:before {
            @include max-mq ($sm - 1) {
                background-image: -ms-linear-gradient(top left, #EF8408 0%, #EE4C22 100%);
                background-image: -moz-linear-gradient(top left, #EF8408 0%, #EE4C22 100%);
                background-image: -o-linear-gradient(top left, #EF8408 0%, #EE4C22 100%);
                background-image: -webkit-gradient(linear, left top, right bottom, color-stop(0, #EF8408), color-stop(100, #EE4C22));
                background-image: -webkit-linear-gradient(top left, #EF8408 0%, #EE4C22 100%);
                background-image: linear-gradient(to bottom right, #EF8408 0%, #EE4C22 100%);
                border-radius: 50px 0 0 50px;
                bottom: 0;
                content: '';
                display: block;
                height: 100%;
                left: auto;
                margin: auto;
                position: absolute;
                right: 0;
                top: 0;
                width: 75%;
                max-width: 100%;
                z-index: -1;
            }
        }
    }
}
.text-image-section-wrapper {
    &:not(.hero-section-wrapper) {
        padding: 46px 0 35px;
        @include max-mq ($sm - 1) {
            padding: 23px 0 35px;
        }
        @include max-mq ($ip) {
            padding: 15px 0 25px;
        }
        .text-image-section-description {
            padding-bottom: 55px;
            @include min-mq ($mac) {
                padding-right: 19px;
                padding-bottom: 86px;
            }
            @include max-mq ($sm - 1) {
                padding-bottom: 0;
            }
            p {
                margin-bottom: 1.5em;
            }
        }
    }
    
    .text-image-heading-text-description-inner {
        padding: 0 37px;
        @include max-mq ($md) {
            padding: 0;
        }
    }
}
.text-image-main-image-wrap {
    position: relative;
}
.text-image-section-heading {
    max-width: 500px;
    padding-right: 10px;
}
.text-image-row {
    align-items: center;
}